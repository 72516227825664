'use client'

import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Button } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useRouter } from 'next/router';

export default function CTAButton() {
    const [isMobile, setIsMobile] = useState(false);
    const [buttonPosition, setButtonPosition] = useState('1rem');
    const router = useRouter();

    useEffect(() => {
        const checkDeviceType = () => {
            const mobileWidth = 768; 
            setIsMobile(window.innerWidth < mobileWidth);
        };

        checkDeviceType();
        window.addEventListener('resize', checkDeviceType);

        const footer = document.querySelector('#footer');
        
        const updateButtonPosition = () => {
            const windowHeight = window.innerHeight;
            const footerRect = footer.getBoundingClientRect();
            const footerTopVisible = footerRect.top - windowHeight;
            
            const footerVisibleHeight = Math.min(windowHeight, Math.max(0, -footerTopVisible));

            const progress = Math.min(1, footerVisibleHeight / 80);

            const minBottom = 1;
            const maxBottom = 6;
            const dynamicBottom = minBottom + (maxBottom - minBottom) * progress;
            setButtonPosition(`${dynamicBottom}rem`);
        };

        updateButtonPosition();
        window.addEventListener('scroll', updateButtonPosition);
        window.addEventListener('resize', updateButtonPosition);

        return () => {
            window.removeEventListener('resize', checkDeviceType);
            window.removeEventListener('scroll', updateButtonPosition);
            window.removeEventListener('resize', updateButtonPosition);
        };
    }, []);

    if (router.pathname === '/contact') {
        return null;
    }
    
    return (
        <Link href="/contact" passHref>
            <Button className='bg-neutral-dark font-bold text-primary'
                variant="contained" 
                aria-label="kontaktiere mich"
                sx={{
                    position: 'fixed',
                    bottom: buttonPosition, // Use state value
                    right: '1rem',
                    zIndex: 1000,
                    borderRadius: isMobile ? '50%' : '4px', // Kreisförmig auf Mobilgeräten, viereckig auf Desktops
                    minWidth: isMobile ? '56px' : 'auto', // Minimale Breite auf Mobilgeräten
                    height: isMobile ? '56px' : 'auto', // Höhe auf Mobilgeräten
                    padding: isMobile ? '0' : '6px 12px', // Padding auf Desktops
                    '&:hover': {
                        backgroundColor: '#222222',
                        color: '#09b872'
                    },
                }}
            >
                {isMobile ? <MailOutlineIcon /> : <div>Kontaktieren Sie Uns <MailOutlineIcon /></div>}
            </Button>
        </Link>
    );
}