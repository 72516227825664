import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { signIn } from 'next-auth/react';
import { ValidateEmail } from './functions/GeneralFunctions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const LoginModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [emailError, setEmailError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const isEmailValid = ValidateEmail(formData.email);
    setEmailError(!isEmailValid);

    const isFormValid = formData.email && isEmailValid && formData.password;
    setIsButtonDisabled(!isFormValid);
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = formData.email;
    const password = formData.password;
    const result = await signIn('credentials', {
      redirect: false,
      email,
      password,
    });

    if (!result.error) {
      handleClose();
    } else {
      alert(result.error)
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit} className='bg-primary-light text-neutral-dark'>
        <Typography id="login-modal-title" variant="h6" component="h2">
          Login
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="E-Mail"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={emailError}
          helperText={emailError ? "Bitte geben Sie eine gültige E-Mail-Adresse ein." : ""}
          className='bg-primary-light-secondary contactform-input'
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className='bg-primary-light-secondary contactform-input'
        />
        <Button type="submit" fullWidth variant="contained" disabled={isButtonDisabled} className="bg-neutral-dark text-primary" sx={{mt: 3, mb: 2 ,'&:hover': {
                backgroundColor: '#222222',
                color: '#09b872'
            }}}>
            Login
        </Button>
                <Button onClick={handleClose} fullWidth variant="text"sx={{'&:hover': {
                        color: '#cc7a00'
                    }}}>
                    Abbrechen
                </Button>
      </Box>
    </Modal>
  );
};

export default LoginModal;
