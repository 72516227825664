import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#09b872',
        },
        secondary: {
            main: '#33cd8f',
        },
    },
    typography: {
        fontFamily: 'Merriweather',
        h1: {
            fontSize: '2.3rem',
            fontWeight: 'bold'
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '1.4rem',
            fontWeight: 'bold'
        },
        h4: {
            fontSize: '1.3rem',
            fontWeight: 'bold'
        },
        h5: {
            fontSize: '1.2rem',
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '1.1rem',
            fontWeight: 'bold'
        },
        caption: {
            fontSize: '0.8rem'
        },
        body1: {
            fontSize: '1rem'
        }
    },
    components: {
      // Name of the component
      MuiTextField: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            input: {
              color: 'white', // Or any color you want
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'lightgray', // Or any color you want
            '&.Mui-focused': {
              color: 'white', // Or any color you want
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: '#327e32', // Example background color
            color: 'lightgray', // Or any color you want
            '&.Mui-focused': {
              color: 'white', // Or any color you want
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: 'lightgray', // Or any color you want
            },
            '&:hover fieldset': {
              borderColor: 'white', // Or any color you want
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white', // Or any color you want
            },
          },
        },
      },
    },
});

export default theme;
