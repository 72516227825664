'use client'

import React, { useState } from "react";
import { Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Navbar, NavbarBrand, NavbarMenuToggle, NavbarMenuItem, NavbarMenu, NavbarContent, NavbarItem, Link, Button, DropdownMenu, DropdownItem, Dropdown, DropdownTrigger } from "@nextui-org/react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from "next/image";
import { signOut, useSession } from "next-auth/react";
import SignupModal from "./SignupModal";
import LoginModal from "./LoginModal";

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data: session, status } = useSession();
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [openLoginModal, setOpenLoginModel] = useState(false);
  const [expanded, setExpanded] = useState(false);


  const handleOpenLogin = () => setOpenLoginModel(true);
  const handleCloseLogin = () => setOpenLoginModel(false);
  const handleOpenSignup = () => setOpenSignupModal(true);
  const handleCloseSignup = () => setOpenSignupModal(false);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Navbar className="bg-primary-light text-neutral-dark navbar" isBlurred isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} maxWidth="2xl" style={{ zIndex: '1001' }}>
        <NavbarBrand>
          <Link href="/" className="font-bold text-inherit" size="lg">
            <Image src="/images/logo.png" height={40} width={80} alt="Logo" style={{ paddingRight: '0.5rem' }} />
            Thursdays
          </Link>
        </NavbarBrand>
        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          <NavbarItem>
            <Dropdown showArrow>
              <DropdownTrigger>
                <Button
                  className="font-bold">
                  Smash Bros Ultimate
                </Button>
              </DropdownTrigger>
              <DropdownMenu className="bg-primary-light text-neutral-dark dropdown-navigation custom-dropdown-menu" aria-label="SmashBrosUltimate">
                <DropdownItem>
                  <Typography>
                    <Link href="/smashbrosultimate" className="font-bold block w-full text-neutral-dark" size="lg">
                      Übersicht
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem>
                  <Typography>
                    <Link href="/smashbrosultimate/king" className="font-bold block w-full text-neutral-dark" size="lg">
                      König
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem>
                  <Typography>
                    <Link href="/smashbrosultimate/statistics" className="font-bold block w-full text-neutral-dark" size="lg">
                      Statistik
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem>
                  <Typography>
                    <Link href="/smashbrosultimate/tournament" className="font-bold block w-full text-neutral-dark" size="lg">
                      Turnier
                    </Link>
                  </Typography>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem>
            <Dropdown showArrow>
              <DropdownTrigger>
                <Button
                  className="font-bold">
                  Star Wars Unlimited
                </Button>
              </DropdownTrigger>
              <DropdownMenu className="bg-primary-light text-neutral-dark dropdown-navigation custom-dropdown-menu" aria-label="StarWarsUnlimited">
                <DropdownItem>
                  <Typography>
                    <Link href="/starwarsunlimited" className="font-bold block w-full text-neutral-dark" size="lg">
                      Übersicht
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem>
                  <Typography>
                    <Link href="/starwarsunlimited/cards" className="font-bold block w-full text-neutral-dark" size="lg">
                      Karten
                    </Link>
                  </Typography>
                </DropdownItem>
                {session && status !== "loading" && (
                  <DropdownItem>
                    <Typography>
                      <Link href="/starwarsunlimited/mycards" className="font-bold block w-full text-neutral-dark" size="lg">
                        Meine Karten
                      </Link>
                    </Typography>
                  </DropdownItem>
                )}
                {session && status !== "loading" && (
                  <DropdownItem>
                    <Typography>
                      <Link href="/starwarsunlimited/trade" className="font-bold block w-full text-neutral-dark" size="lg">
                        Tauschbörse
                      </Link>
                    </Typography>
                  </DropdownItem>
                )}
                {/*
                <DropdownItem>
                  <Typography>
                    <Link href="/starwarsunlimited/shop" className="font-bold block w-full text-neutral-dark" size="lg">
                      Shop
                    </Link>
                  </Typography>
                </DropdownItem>
                <DropdownItem>
                  <Typography>
                    <Link href="/starwarsunlimited/tournament" className="font-bold block w-full text-neutral-dark" size="lg">
                      Turnier
                    </Link>
                  </Typography>
                </DropdownItem>
                */}
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem>
            <Link href="/contact" size="lg" className="font-bold text-neutral-dark">
              Kontakt
            </Link>
          </NavbarItem>
        </NavbarContent>
        <NavbarContent className="hidden sm:flex gap-4" justify="end">
          {!session && status !== "loading" && (
            <>
              <Button color="inherit" onClick={handleOpenLogin}>Login</Button>
              <Button color="inherit" onClick={handleOpenSignup}>Sign Up</Button>
            </>
          )}
          {session && status !== "loading" && (
            <>
              <Link href="/account" size="lg" className="font-bold text-neutral-dark">
                <Typography>{`${session.user.name}`}</Typography>
              </Link>
              <Button color="inherit" onClick={() => signOut()}>Logout</Button>
            </>
          )}
        </NavbarContent>

        <NavbarContent className="sm:hidden" justify="end">
          <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
        </NavbarContent>

        <NavbarMenu className="bg-primary-light" style={{ paddingTop: '2.5rem', zIndex: '1001' }}>
          <Accordion className="bg-primary-light text-neutral-dark nav-accordion-header" expanded={expanded === 'smashBrosPanel'} onChange={handleAccordionChange('smashBrosPanel')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="smashBrosPanel-content"
              id="smashBrosPanel-header"
              className="text-neutral-dark"
            >
              <Typography>Smash Bros Ultimate</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Link href="/smashbrosultimate" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                Übersicht
              </Link>
              <Link href="/smashbrosultimate/king" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                König
              </Link>
              <Link href="/smashbrosultimate/statistics" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                Statistik
              </Link>
              <Link href="/smashbrosultimate/tournament" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                Turnier
              </Link>
            </AccordionDetails>
          </Accordion>


          <Accordion className="bg-primary-light text-neutral-dark nav-accordion-header" expanded={expanded === 'starWarsPanel'} onChange={handleAccordionChange('starWarsPanel')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="starWarsPanel-content"
              id="starWarsPanel-header"
            >
              <Typography>Star Wars Unlimited</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Link href="/starwarsunlimited" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                Übersicht
              </Link>
              <Link href="/starwarsunlimited/cards" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                Karten
              </Link>
              {session && status !== "loading" && (
                <Link href="/starwarsunlimited/mycards" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                  Meine Karten
                </Link>)}

              {session && status !== "loading" && (
                <Link href="/starwarsunlimited/trade" className="font-bold block w-full text-neutral-dark nav-accordion-link" size="lg">
                  Tauschbörse
                </Link>
              )}
              {/*
                  <Link href="/starwarsunlimited/shop" className="font-bold block w-full text-neutral-dark" size="lg">
                    Shop
                  </Link>
                  <Link href="/starwarsunlimited/tournament" className="font-bold block w-full text-neutral-dark" size="lg">
                    Turnier
                  </Link>*/}
            </AccordionDetails>
          </Accordion>
          <NavbarMenuItem className="mobile-nav-item">
            <Typography color="primary">
              <Link href="/contact" className="font-bold block w-full text-neutral-dark" size="lg">
                Kontakt
              </Link>
            </Typography>
          </NavbarMenuItem>
          {!session && status !== "loading" && (
            <>
              <NavbarMenuItem className="mobile-nav-item">
                <Typography color="primary">
                  <Button className="font-bold block w-full text-neutral-dark" size="lg" onClick={handleOpenLogin}>Login</Button>
                </Typography>
              </NavbarMenuItem>
              <NavbarMenuItem className="mobile-nav-item">
                <Typography color="primary">
                  <Button className="font-bold block w-full text-neutral-dark" size="lg" onClick={handleOpenSignup}>Sign Up</Button>
                </Typography>
              </NavbarMenuItem>
            </>
          )}
          {session && status !== "loading" && (
            <>
              <NavbarMenuItem className="mobile-nav-item">
                <Link href="/account" size="lg" className="font-bold text-neutral-dark">
                  <Typography className="block w-full text-neutral-dark" size="lg">{`${session.user.name}`}</Typography>
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem className="mobile-nav-item">
                <Typography color="primary">
                  <Button className="font-bold block w-full text-neutral-dark" size="lg" onClick={() => signOut()}>Logout</Button>
                </Typography>
              </NavbarMenuItem>
            </>
          )}
        </NavbarMenu>
      </Navbar>

      <SignupModal open={openSignupModal} handleClose={handleCloseSignup} />
      <LoginModal open={openLoginModal} handleClose={handleCloseLogin} />
    </>
  );
}
