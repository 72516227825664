'use client'

import React from "react";
import { Link, Card, CardBody } from "@nextui-org/react";

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className="footer" id="footer">
            <Card className="footer-card bg-primary-light" fullWidth radius="none">
                <CardBody className="items-center items-centered">
                    <div className="flex gap-4">
                        <Link href="/privacy" className="text-neutral-dark">Datenschutz</Link>
                        <p className="text-neutral-dark">&copy; Copyright {year}</p>
                        <Link href="/impressum" className="text-neutral-dark">Impressum</Link>
                    </div>
                </CardBody>
            </Card>
        </footer>
    );
}