import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { ValidateEmail } from './functions/GeneralFunctions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const SignupModal = ({ open, handleClose }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
    });
    const [emailError, setEmailError] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const isEmailValid = ValidateEmail(formData.email);
        setEmailError(!isEmailValid);

        const isFormValid = formData.firstName && formData.lastName && formData.email && isEmailValid && formData.password;
        setIsButtonDisabled(!isFormValid);
    }, [formData]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('/api/auth/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password,
                    firstname: formData.firstName,
                    lastname: formData.lastName,
                }),
            });
            if (response.ok) {
                handleClose();
                // You might want to add more logic here to handle successful signup
            } else {
                alert('Signup failed');
                // Here you could extract error details and display to the user
            }
        } catch (error) {
            alert('An error occurred during signup.');
            console.error(error);
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
        }
    };


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="signup-modal-title"
            aria-describedby="signup-modal-description"
        >

            <Box sx={style} component="form" onSubmit={handleSubmit} className='bg-primary-light text-neutral-dark'>
                <Typography id="signup-modal-title" variant="h6" component="h2">
                    Registrieren
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Vorname"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className='bg-primary-light-secondary contactform-input'
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Nachname"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className='bg-primary-light-secondary contactform-input'
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="E-Mail"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={emailError}
                    helperText={emailError ? "Bitte geben Sie eine gültige E-Mail-Adresse ein." : ""}
                    className='bg-primary-light-secondary contactform-input'
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className='bg-primary-light-secondary contactform-input'
                />
                <Button 
                    type="submit" 
                    fullWidth 
                    variant="contained" 
                    disabled={isButtonDisabled || loading} // Disable the button when loading
                    className="bg-neutral-dark text-primary"
                    sx={{ mt: 3, mb: 2, '&:hover': { backgroundColor: '#222222', color: '#09b872' } }}
                >
                    {loading ? <CircularProgress size={24} /> : 'Registrieren'}
                </Button>
                <Button onClick={handleClose} fullWidth variant="text" sx={{
                    '&:hover': {
                        color: '#cc7a00'
                    }
                }}>
                    Abbrechen
                </Button>
            </Box>
        </Modal>
    );
};

export default SignupModal;