
export const IsBaseOrLeader = (cardType) => cardType === 'Base' || cardType === 'Leader';

export const ValidateEmail = (email) => {
  if (!email) 
  {
      return true;
  }
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export async function AddTournamentResults(bodyData) {
  try {
    const response = await fetch('/api/smashbros/addTournamentResults', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error('Failed to save tournament results');
    }

    const result = await response.json();
    console.log('Tournament results saved:', result);
    return true;

  } catch (error) {
    console.error('Error saving tournament results:', error);
    return false;
  }
}


export function CalculateRunningAverage(inputArray) {
  let runningSum = 0;
  let count = 0; // Keep track of the non-null items processed
  const runningAverageArray = [];

  for (let i = 0; i < inputArray.length; i++) {
    // Check if the current item is not null
    if (inputArray[i] !== null) {
      runningSum += inputArray[i];
      count++;
      runningAverageArray.push(runningSum / count);
    } else {
      // If the item is null, push null to the runningAverageArray
      runningAverageArray.push(null);
    }
  }

  return runningAverageArray;
}


export const InitPlayers = (players) => {
  var initialPlayers = [];
  players.forEach(player => {
    initialPlayers.push(player.name);
  });
  const validLengths = [4, 8, 16, 32, 64];
  let cpuId = 1;
  while (!validLengths.includes(initialPlayers.length)) {
    initialPlayers.push('CPU-' + cpuId++);
  };
  var xPosition = 50;
  var yPosition = 50;
  var tournamentPlayers = [{ round: 1, maxGroupCounter: initialPlayers.length / 2, players: [] }];
  var groupCounter = 1;
  var counter = 0;
  reorderArray(initialPlayers).forEach(player => {
    tournamentPlayers[0].players.push({ name: player, position: { x: xPosition, y: yPosition }, group: groupCounter, previousGroup: 0 });
    yPosition += 50;
    counter++
    if (counter % 2 === 0) {
      groupCounter++;
    }
  });
  return tournamentPlayers;
};

function reorderArray(arr) {
  let result = [];
  let start = 0;
  let end = arr.length - 1;
  while (start <= end) {
    if (start === end) {
      result.push(arr[start]);
      break;
    }
    result.push(arr[start]);
    result.push(arr[end]);

    start++;
    end--;
  }


  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  const chunks = chunk(result, 2);

  const firstHalf = [];
  const secondHalf = [];
  for (let i = 0; i < chunks.length; i += 2) {
    firstHalf.push(chunks[i]);
    if (i + 1 < chunks.length) {
      secondHalf.push(chunks[i + 1]);
    }
  }

  const reversedSecondHalf = secondHalf.reverse();
  return [...firstHalf, ...reversedSecondHalf].flat();
}

export function GetRandomUnplayedCombo(players) {
  const unplayedCombos = findUnplayedCombos(players);
  if (unplayedCombos.length === 0) {
    return null; // or throw an error if preferred
  }
  const randomIndex = Math.floor(Math.random() * unplayedCombos.length);
  return unplayedCombos[randomIndex];
}

function findUnplayedCombos(players) {
  const unplayedCombos = [];

  // Loop through all players
  for (let i = 0; i < players.length; i++) {
    for (let j = i + 1; j < players.length; j++) {
      const player1 = players[i];
      const player2 = players[j];

      // Check if player2's name is not in player1's playedNames array and vice versa
      if (!player1.mode1v1.playedNames.includes(player2.name) &&
        !player2.mode1v1.playedNames.includes(player1.name)) {
        unplayedCombos.push({ player1: player1.name, player2: player2.name });
      }
    }
  }
  console.log(unplayedCombos)

  return unplayedCombos;
}

export const CalculateMatchRating = (kills, deaths, rank, totalPossibleKillsInMatch, lifes) => {
  const maxPointsForDeaths = 2;
  let deathRating = maxPointsForDeaths;
  if (deaths > 0) {
    deathRating = maxPointsForDeaths * (1 - deaths / lifes)
  }

  const maxPointsForKd = 2;
  let highestPossibleKd = totalPossibleKillsInMatch / lifes;
  let kDRating = maxPointsForKd;
  if (deaths > 0) {
    let kdRatio = kills / deaths;
    kDRating = (kdRatio / highestPossibleKd) * maxPointsForKd;
    kDRating = Math.min(kDRating, maxPointsForKd);
  }

  const maxPointsForKills = 5;
  let killRating = 0;
  if (kills > 0) {
    killRating = maxPointsForKills / (totalPossibleKillsInMatch / kills);
  }

  const maxPointsForRanking = 4;
  let rankRating = maxPointsForRanking / rank;
  return killRating + kDRating + deathRating + rankRating
}

export const CheckForDuplications = (arr) => {
  const result = arr.reduce((acc, item, index) => {
    if (acc.foundDuplicate) return acc;

    acc.namesCount[item.name] = (acc.namesCount[item.name] || 0) + 1;

    if (acc.namesCount[item.name] > 1) {
      acc.foundDuplicate = true;
    }

    return acc;
  }, { namesCount: {}, foundDuplicate: false });

  return result.foundDuplicate;
};

export const CalculateAverageValue = (arr) => {
  if (arr.length === 0) return 0;
  const sum = arr.reduce((acc, val) => acc + Number(val), 0);
  return sum / arr.length;
};

export const CountAmountInArr = (arr, val) => {
  return arr.reduce((count, num) => { return count + (num === val ? 1 : 0) }, 0);
};

export const SumValuesInArr = (arr) => {
  return arr.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
};

export const GeneratePlayersData = (data, uniqueDates) => {
  const playersData = data.reduce((acc, data) => {
    if (!acc[data.Name]) {
      acc[data.Name] = uniqueDates.map(Date => ({
        Date,
        TotalRating: null,
        SingleRating: null,
        MultiRating: null,
        TournamentRank: null,
        AverageMultiKills: null,
        AverageSingleKills: null,
        AverageMultiDeaths: null,
        AverageSingleDeaths: null
      }));
    }
    const dateIndex = acc[data.Name].findIndex(entry => entry.Date === data.Date);
    if (dateIndex !== -1) {
      acc[data.Name][dateIndex].TotalRating = data.TotalRating;
      acc[data.Name][dateIndex].SingleRating = data.SingleRating;
      acc[data.Name][dateIndex].MultiRating = data.MultiRating;
      acc[data.Name][dateIndex].TournamentRank = data.TournamentRank;
      acc[data.Name][dateIndex].AverageMultiKills = data.MultiKills / data.MultiMatches;
      acc[data.Name][dateIndex].AverageSingleKills = data.SingleKills / data.SingleMatches;
      acc[data.Name][dateIndex].AverageMultiDeaths = data.MultiDeaths / data.MultiMatches;
      acc[data.Name][dateIndex].AverageSingleDeaths = data.SingleDeaths / data.SingleMatches;
    }
    return acc;
  }, {});
  return playersData;
}

export const ProcessData = (data) => {
  const players = {};
  let previousPlayer = '';
  var sortedData = data.sort((a, b) => b.TournamentRank - a.TournamentRank || new Date(a.Date) - new Date(b.Date));
  sortedData.forEach(item => {
    if (!players[item.Name]) {
      players[item.Name] = {
        Name: item.Name,
        TotalTournamentWins: 0,  // for ranking players
        MostWinsInARow: 0,
        Counts: {                // to keep track of non-zero counts for averages
          MultiRating: 0,
          SingleRating: 0,
          PreRank: 0,
          TotalRating: 0,
          TournamentRank: 0,
          WinsInARow: 0
        },
        AverageMultiRank: 0,
        AverageSingleRank: 0,
        MultiDeaths: 0,
        MultiKills: 0,
        MultiMatches: 0,
        MultiRating: 0,
        PreRank: 0,
        SingleDeaths: 0,
        SingleKills: 0,
        SingleMatches: 0,
        SingleRating: 0,
        TotalRating: 0,
        TournamentRank: 0,
      };
    }

    const player = players[item.Name];

    player.MultiDeaths += item.MultiDeaths;
    player.MultiKills += item.MultiKills;
    player.MultiMatches += item.MultiMatches;
    player.SingleDeaths += item.SingleDeaths;
    player.SingleKills += item.SingleKills;
    player.SingleMatches += item.SingleMatches;

    if (item.MultiRating !== 0) {
      player.MultiRating += item.MultiRating;
      player.Counts.MultiRating++;
    }

    if (item.SingleRating !== 0) {
      player.SingleRating += item.SingleRating;
      player.Counts.SingleRating++;
    }

    if (item.preRank !== 0) {
      player.preRank += item.preRank;
      player.Counts.preRank++;
    }

    if (item.TotalRating !== 0) {
      player.TotalRating += item.TotalRating;
      player.Counts.TotalRating++;
    }

    if (previousPlayer !== player.Name) {
      player.Counts.WinsInARow = 0;
    }
    if (item.TournamentRank !== 0) {
      player.TournamentRank += item.TournamentRank;
      player.Counts.TournamentRank++;
      if (item.TournamentRank === 1) {
        player.Counts.WinsInARow++;
        if (player.Counts.WinsInARow > player.MostWinsInARow) {
          player.MostWinsInARow = player.Counts.WinsInARow;
        }
      }
    }

    player.TotalTournamentWins += (item.TournamentRank === 1) ? 1 : 0;
    previousPlayer = player.Name;
  });

  const finalPlayers = Object.values(players).map(player => {
    if (player.Counts.MultiRating > 0) {
      player.MultiRating /= player.Counts.MultiRating;
    }

    if (player.Counts.SingleRating > 0) {
      player.SingleRating /= player.Counts.SingleRating;
    }

    if (player.Counts.preRank > 0) {
      player.preRank /= player.Counts.preRank;
    }

    if (player.Counts.TotalRating > 0) {
      player.TotalRating /= player.Counts.TotalRating;
    }

    if (player.Counts.TournamentRank > 0) {
      player.TournamentRank /= player.Counts.TournamentRank;
    }

    delete player.Counts;
    return player;
  });

  finalPlayers.sort((a, b) =>
    b.TotalTournamentWins - a.TotalTournamentWins ||
    b.TotalRating - a.TotalRating ||
    a.Name.localeCompare(b.Name));

  return finalPlayers;
};